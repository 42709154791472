import React, { useState, useEffect } from "react";
import Question1 from "./Questions/Question1";
import Question2 from "./Questions/Question2";
import Question3 from "./Questions/Question3";
import Question4 from "./Questions/Question4";
import Question5 from "./Questions/Question5";
import Question6 from "./Questions/Question6";
import { Progress } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import Layout from "../LayoutSite";
import { MagnifyingGlass } from "react-loader-spinner";
import Question7 from "./Questions/Question7";

export default function Form() {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const totalSteps = 8; // Change this to the total number of steps in your form
  const progressValue = Math.round((step / (totalSteps - 1)) * 100);
  const [selectedOptions, setSelectedOptions] = useState(() => {
    const savedResponses = JSON.parse(localStorage.getItem("formResponses"));
    return savedResponses || {};
  });

  useEffect(() => {
    localStorage.setItem("formResponses", JSON.stringify(selectedOptions));
  }, [selectedOptions]);

  const handleRadioChange = (questionName, selectedOption) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [questionName]: selectedOption,
    }));
  };

  const [surveyStarted, setSurveyStarted] = useState(false);

  const startSurvey = () => {
    setSurveyStarted(true);
  };

  const StepDisplay = () => {
    switch (step) {
      case 0:
        return (
          <Question1
            question={{
              question:
                "Which aspect of your identity holds significance for you: Ethnicity",
              name: "question1",
              options: [
                "Asian",
                "Black",
                "Hispanic and Latino",
                "Indigenous Heritage",
                "This does not hold significance for me",
              ],
            }}
            selectedOption={selectedOptions["question1"]}
            handleRadioChange={(option) =>
              handleRadioChange("question1", option)
            }
          />
        );
      case 1:
        return (
          <Question2
            question={{
              question:
                "Which aspect of your identity holds significance for you: Sexuality",
              name: "question2",
              options: [
                "Straight",
                "LGBTQ+",
                "Bisexual",
                "Lesbian",
                "Queer",
                "This does not hold significance for me",
              ],
            }}
            selectedOption={selectedOptions["question2"]}
            handleRadioChange={(option) =>
              handleRadioChange("question2", option)
            }
          />
        );
      case 2:
        return (
          <Question3
            question={{
              question:
                "Which aspect of your identity holds significance for you: Faith",
              name: "question3",
              options: [
                "Christian",
                "Muslim",
                "Hindu",
                "Sikh",
                "Spiritual but not religious",
                "Atheist",
                "This does not hold significance for me",
              ],
            }}
            selectedOption={selectedOptions["question3"]}
            handleRadioChange={(option) =>
              handleRadioChange("question3", option)
            }
          />
        );
      case 3:
        return (
          <Question4
            question={{
              question: "Is the gender of your therapist important to you?",
              name: "question4",
              options: [
                "No, this is not important",
                "I prefer a woman",
                "I prefer a man",
                "I prefer a non-binary person",
              ],
            }}
            selectedOption={selectedOptions["question4"]}
            handleRadioChange={(option) =>
              handleRadioChange("question4", option)
            }
          />
        );

      case 4:
        return (
          <Question5
            question={{
              question: "Is the religion of your therapist important to you?",
              name: "question5",
              options: [
                "No, this is not important",
                "I prefer a Christian",
                "I prefer a Muslim",
                "I prefer a Hindu",
                "I prefer a non-religious person",
              ],
            }}
            selectedOption={selectedOptions["question5"]}
            handleRadioChange={(option) =>
              handleRadioChange("question5", option)
            }
          />
        );

      case 5:
        return (
          <Question6
            question={{
              question: "Who are you seeking therapy for?",
              name: "question6",
              options: [
                "For myself",
                "For myself and my partner as a couple",
                "For my whole family",
                "For a child (12 and under)",
                "For a teen",
                "For someone else (another adult)",
              ],
            }}
            selectedOption={selectedOptions["question6"]}
            handleRadioChange={(option) =>
              handleRadioChange("question6", option)
            }
          />
        );
      case 6:
        return (
          <Question7
            question={{
              question: "Is money tight for you?",
              name: "question6",
              options: [
                "Money is not a problem",
                "I want something affordable",
                "The lower the price, the better for me",
                "I want a therapist with a sliding scale",
              ],
            }}
            selectedOption={selectedOptions["question7"]}
            handleRadioChange={(option) =>
              handleRadioChange("question7", option)
            }
          />
        );
      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    // console.log(selectedOptions);
    try {
      const response = await fetch(
        "https://mhapy.ca/api/v1/therapists/user-response",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ selectedOptions }),
        }
      );

      if (response.ok) {
        const bestMatchedTherapists = await response.json();
        navigate("/show-therapists", { state: { bestMatchedTherapists } });
        setLoading(false);
      } else {
        console.error("Failed to send data to the backend");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="flex flex-col min-h-screen justify-center overflow-hidden w-2/3 mx-auto">
        {!surveyStarted ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-3xl font-bold mb-4">
              Tell us about what is important to you.
            </h1>
            <p className="text-lg text-center mb-8">
              Which aspects of your identity hold the most significance for you?
            </p>
            <button
              className="px-6 py-3 bg-primary text-white rounded-md"
              onClick={startSurvey}
            >
              Next
            </button>
          </div>
        ) : (
          <>
            {loading && (
              <div className="fixed top-0 left-0 w-full bg-gray-700 opacity-75 z-50 flex items-center justify-center h-screen">
                <MagnifyingGlass
                  visible={true}
                  height="120"
                  width="120"
                  ariaLabel="MagnifyingGlass-loading"
                  wrapperStyle={{}}
                  wrapperClass="MagnifyingGlass-wrapper"
                  glassColor="#fff"
                  color="#441890"
                />
              </div>
            )}
            <div className="mx-auto w-full mb-[30px] mt-5">
              <Progress
                color="purple"
                value={progressValue}
                label="Completed"
              />
            </div>
            <div className="flex flex-col gap-3">
              <div>{StepDisplay()}</div>
              {step === totalSteps - 1 ? (
                <div className="flex flex-col items-center">
                  <strong>Thank you for sharing.</strong>
                  <button
                    className="px-6 py-3 bg-primary text-white rounded-md mt-4"
                    onClick={handleSubmit}
                  >
                    See which therapists match your preferences.
                  </button>
                </div>
              ) : (
                <div className="flex flex-row justify-between px-3 py-5">
                  {step > 0 && (
                    <button
                      className="px-5 py-3 bg-primary text-white rounded-md"
                      onClick={() => {
                        setStep((currPage) => currPage - 1);
                      }}
                    >
                      Prev
                    </button>
                  )}
                  <button
                    className="px-5 py-3 bg-primary text-white rounded-md"
                    disabled={!selectedOptions[`question${step + 1}`]}
                    onClick={() => {
                      setStep((currPage) => currPage + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}