import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../assets/images/logo.jpg";
import { useNavigate, Link } from "react-router-dom";


function NavList() {
  return (
    <ul className="my-2 flex flex-col gap-5 md:mb-0 md:mt-0 md:flex-row md:items-center md:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <Link to="/">
          <button
            href="#"
            className="flex items-center hover:text-primary
           transition-colors"
          >
            Home
          </button>
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <Link to="/about-us"> 
          <button
            href="#"
            className="flex items-center hover:text-primary transition-colors"
          >
            About Us
          </button>
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <button
          href="#"
          className="flex items-center hover:text-primary transition-colors"
        >
          Services
        </button>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <button
          href="#"
          className="flex items-center hover:text-primary transition-colors"
        >
          FAQ
        </button>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <button
          href="#"
          className="flex items-center hover:text-primary transition-colors"
        >
          Contact
        </button>
      </Typography>
    </ul>
  );
}

export default function Nav() {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = React.useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const search = () => {
    navigate("/search-therapists");
  };

  return (
    <Navbar className="mx-auto max-w-full px-6 py-3 rounded-none">
      <div className="flex items-center justify-between text-blue-gray-900 px-12">
        <Typography
          as="a"
          href="#"
          variant="h6"
          className="mr-4 cursor-pointer py-1.5"
        >
          <Link to="/">
            {" "}
            <img src={Logo} alt="logo" />
          </Link>
        </Typography>
        <div className="hidden md:block justify-center items-center">
          <NavList />
        </div>
        <div className="flex items-center gap-x-1">
          <button
            onClick={search}
            className="hidden md:inline-block bg-primary text-white py-2 px-4 rounded"
          >
            <span>Search Therapists</span>
          </button>
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent md:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
        <div className="flex items-center gap-x-1">
          <button
            onClick={search}
            className="bg-primary text-white p-2 rounded w-full"
          >
            <span>Search Therapists</span>
          </button>
        </div>
      </Collapse>
    </Navbar>
  );
}
