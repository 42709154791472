import React from "react";
import Nav from "../components/Nav";
import { Typography } from "@material-tailwind/react";
import Mockup from "../assets/images/mockup.png";

const AboutUs = () => {
  // Define the description text
  const descriptionText =
    "mhapy is not just a chatbot or a social journal; it's your guide in understanding and managing anxiety, depression, sleep patterns, and overall well-being. With mhapy, you're not alone. Connect with peers who share similar experiences and embark on a journey of mutual support and growth. Take control of your mental health journey with mhapy. Additionally, our platform provides a unique feature to help you find your ideal therapist. We understand that everyone's needs are different, so we tailor our recommendations based on your personal preferences, ensuring that you receive the support that best fits your individual requirements.";

  // Find the index where to split the description
  const splitIndex = descriptionText.indexOf("Additionally");

  // Split the description into two parts
  const firstPart = descriptionText.substring(0, splitIndex);
  const secondPart = descriptionText.substring(splitIndex);

  return (
    <div>
      <Nav />
      <div className="about-us-container" style={{ textAlign: "center" }}>
        <header className="header">
          <Typography variant="h1" color="blue-gray" className="title">
            About Us
          </Typography>
        </header>
        <section className="description-section">
          <div className="main-content">
            <div className="description-pair">
              <div className="description-container">
                <Typography
                  variant="h2"
                  color="blue-gray"
                  className="headline"
                >
                  Empower Your Mental Health Journey
                </Typography>
                <p className="description">{firstPart}</p>
              </div>
              <div className="description-container">
                <Typography
                  variant="h2"
                  color="blue-gray"
                  className="headline"
                >
                  Discover Your Perfect Therapist Match
                </Typography>
                <p className="description">{secondPart}</p>
              </div>
            </div>
            <div style={{ display: "inline-block" }}>
              <img
                src={Mockup}
                alt="Mhapy Mockup"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
