import React from "react";
import Layout from "../components/LayoutSite";
import TherapistsCard from "../components/show/TherapistsCard";

export default function ShowTherapists() {
  return (
    <Layout>
      <div className="w-[80%] mx-auto py-8">
        
        <h1 className="my-5 font-bold">Based on your Responses:</h1>
        <p className="font-bold text-2xl">Recommended Therapists For You:</p>
        <div>
          <TherapistsCard />

        </div>
        
      </div>
    </Layout>
  );
}
