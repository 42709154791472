import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SearchTherapists from "./pages/SearchTherapists";
import ShowTherapists from "./pages/ShowTherapists";
import AboutUs from "./pages/AboutUs";
import { clarity } from 'react-microsoft-clarity';

function App() {
  clarity.init('m0u775zauh');
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search-therapists" element={<SearchTherapists />} />
        <Route path="/show-therapists" element={<ShowTherapists />} />
        <Route path="/about-us" element={<AboutUs/>} />
      </Routes>{" "}
    </BrowserRouter>
  );
}

export default App;
