import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BookingInfo from "../BookingInfo";
import BioCard from "../BioCard";

export default function TherapistsCard() {
  const [openBookingDialog, setOpenBookingDialog] = useState(false);
  const [openBio, setOpenBio] = useState(false);
  const [showAllTherapists, setShowAllTherapists] = useState(false);
  const [lowestPrices, setLowestPrices] = useState({});
  const [therapistGenders, setTherapistGenders] = useState({});
  const [slidingScales, setSlidingScales] = useState({});
  const [therapyTypes, setTherapyTypes] = useState({});
  const [spiritualCare, setSpiritualCare] = useState({});
  const location = useLocation();

  const bestMatchedTherapists = location.state?.bestMatchedTherapists || [];
  const allTherapists = bestMatchedTherapists.bestMatchedTherapists;
  const visibleTherapists = showAllTherapists
    ? allTherapists
    : allTherapists.slice(0, 3);

  useEffect(() => {
    // console.log("allTherapists:", allTherapists);
    const prices = {};
    const genders = {};
    const slidingScales = {};
    const therapyTypes = {};
    const spiritualCare = {};
    allTherapists.forEach((therapist) => {
      const bio = therapist.bio;
      const priceRegex = /\$\s?\d+/g;
      const priceMatches = bio.match(priceRegex);
      if (priceMatches) {
        const minPrice = Math.min(
          ...priceMatches.map((match) => parseInt(match.slice(1)))
        );
        prices[therapist.id] = minPrice;
      }
      const genderRegex = /\b(?:he|she|him|her|they|them)\b/i;
      const genderMatch = bio.match(genderRegex);
      if (genderMatch) {
        const gender =
          genderMatch[0].toLowerCase() === "he" ||
          genderMatch[0].toLowerCase() === "him"
            ? "He/him"
            : genderMatch[0].toLowerCase() === "she" ||
              genderMatch[0].toLowerCase() === "her"
            ? "She/her"
            : "They/them";
        genders[therapist.id] = gender;
      }
      const slidingRegex = /\bsliding\b/i;
      const slidingMatch = bio.match(slidingRegex);
      if (slidingMatch) {
        slidingScales[therapist.id] = "Sliding Scale";
      }
      const therapyTypesRegex = /\b(?:individual|couples|family)\b/i;
      const therapyTypesMatch = bio.match(therapyTypesRegex);
      if (therapyTypesMatch) {
        if (therapyTypesMatch[0].toLowerCase() === "individual") {
          therapyTypes[therapist.id] = "Individual Therapy";
        } else if (therapyTypesMatch[0].toLowerCase() === "couples") {
          therapyTypes[therapist.id] = "Couples Therapy";
        } else if (therapyTypesMatch[0].toLowerCase() === "family") {
          therapyTypes[therapist.id] = "Family Therapy";
        }
      }
      const spiritualRegex = /\bspiritual\b/i;
      const spiritualMatch = bio.match(spiritualRegex);
      if (spiritualMatch) {
        spiritualCare[therapist.id] = "Spiritual Care";
      }
    });
    setLowestPrices(prices);
    setTherapistGenders(genders);
    setSlidingScales(slidingScales);
    setTherapyTypes(therapyTypes);
    setSpiritualCare(spiritualCare);
  }, [allTherapists]);

  return (
    <div style={{ overflow: "hidden" }}>
      {visibleTherapists.map((therapist, index) => (
        <div
          key={therapist.id}
          className={`therapist-card flex flex-col md:flex-row p-4 w-full border-2 border-black items-center md:items-stretch mb-4 ${
            index === 0 ? "bg-primary text-white" : ""
          }`}
        >
          <div className="therapist-image-container mb-4 md:mb-0">
            <img
              src={therapist.image}
              alt=""
              className="therapist-image w-[200px] object-cover"
              style={{ height: "220px" }}
            />
          </div>
          <div className="md:flex-1 md:ml-3 flex flex-col gap-3 relative">
            <h1 className="font-bold text-xl text-left">
              {therapist.first_name} {therapist.last_name}
            </h1>
            <p className="mt-5 font-normal w-full md:w-[80%] text-left">
              {/* {therapist.bio.split(' ').length > 30
                ? therapist.bio.split(' ').slice(0, 30).join(' ') + "..."
                : therapist.bio}
              {therapist.bio.split(' ').length > 30 && (
                <BioCard
                  therapistBio={therapist.bio}
                  therapistImage={therapist.image}
                  open={openBio}
                  handleOpen={setOpenBio}
                  index={index}
                  bookingLink={therapist.booking_link}
                />
              )} */}
              {therapist.summary}
              <BioCard
                therapistBio={therapist.bio}
                therapistImage={therapist.image}
                open={openBio}
                handleOpen={setOpenBio}
                index={index}
                bookingLink={therapist.booking_link}
              />
            </p>
            <div className="flex mt-auto flex-wrap">
              {lowestPrices[therapist.id] && (
                <span className="tag">
                  Lowest Price: ${lowestPrices[therapist.id]}
                </span>
              )}
              {therapistGenders[therapist.id] && (
                <span className="tag">
                  {therapistGenders[therapist.id]}
                </span>
              )}
              {slidingScales[therapist.id] && (
                <span className="tag">
                  {slidingScales[therapist.id]}
                </span>
              )}
              {therapyTypes[therapist.id] && (
                <span className="tag">
                  {therapyTypes[therapist.id]}
                </span>
              )}
              {spiritualCare[therapist.id] && (
                <span className="tag">
                  {spiritualCare[therapist.id]}
                </span>
              )}
            </div>
            <div className="hidden md:block absolute top-0 bottom-0 border-r border-black right-0"></div>
          </div>
          <div className="flex md:flex-col ml-0 md:ml-3 flex-row gap-3 mt-3 md:mt-0">
            <BookingInfo
              open={openBookingDialog}
              handleOpen={setOpenBookingDialog}
              therapistEmail={therapist.email}
              therapistLink={therapist.booking_link}
              therapistName={therapist.first_name}
            />
            <div
              className={`rounded-[6px] border-[6px] border-black m-2 ${
                index === 0 ? "bg-white text-primary" : "bg-primary text-white"
              } md:rounded-full`}
            >
              <span className="flex items-center justify-center font-bold m-5">
                {therapist.score} %<br /> match
              </span>
            </div>
          </div>
        </div>
      ))}
      {!showAllTherapists && allTherapists.length > 3 && (
        <button
          className="text-primary underline mt-4"
          onClick={() => setShowAllTherapists(true)}
        >
          See More Therapists
        </button>
      )}
    </div>
  );
}
