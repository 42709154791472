import React from 'react'
import HeroImage from "../../assets/images/Images.png";
import ArrowRight from "../../assets/images/Vector.svg";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();
  const search = () => {
    navigate("/search-therapists");
  };
  
  return (
    <div className="hero md:px-28 px-14 flex md:flex-row flex-col items-center w-full">
        <div className="left w-full flex flex-col gap-6 m-3 items-center md:items-start">
          <h1 className="md:text-[50px] text-[30.5px] md:leading-[91.43px] leading-[45px] font-[600] text-center md:text-left">
            Finding the perfect therapist made easy
          </h1>
          <p className="md:text-[25px] text-[18px] md:leading-[32px] leading-[18px] text-center md:text-left">
            Say goodbye to the overwhelming process of finding a therapist. Join us to experience personalized therapy recommendations.
          </p>

          <button onClick={search} className="py-2 px-8 md:py-4 md:px-16 w-fit rounded bg-primary text-white flex items-center gap-2">
            <span>Get started</span>
            <img src={ArrowRight} alt="" style={{ verticalAlign: 'middle' }} />
          </button>
        </div>
        <div className="right relative w-full flex md:py-20 py-10 justify-center">
          <img src={HeroImage} alt="hero" />
        </div>
      </div>
  )
}

export default Hero