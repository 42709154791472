
import React from "react";

function Question6({ question, selectedOption, handleRadioChange }) {
  return (
    <div>
      <div className='font-bold'>
        <span>{question.question}</span>
      </div>
      <br/>
      {question.options.map((option, index) => (
        <label className="radio-container" key={index}>
          <input
            type="radio"
            name={question.name}
            value={option}
            onChange={() => handleRadioChange(option)}
            checked={selectedOption === option}
            required
          />
          <span className="radio-label">{option}</span>
        </label>
      ))}
    </div>
  );
}

export default Question6;