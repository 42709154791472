import React from 'react'
import Form from '../components/Search/Form'

export default function SearchTherapists() {

  return (
    <>
    <div className='bg-primary_variant'>
    <Form />
    </div>

    </>
  )
}
