// import React, { useEffect, useState } from "react";
import React from "react";
import {
  Input,
  Button,
  Card,
  Carousel,
  CardBody,
  Typography,
  IconButton,
} from "@material-tailwind/react";

import ArrowBlack from "../assets/images/arrow.svg";
import ArrowRight from "../assets/images/Vector.svg";
import ProductIcon from "../assets/images/product_icon.png";
import AchievememtIcon from "../assets/images/achievment.png";
import BadgeIcon from "../assets/images/Vector.png";
import Find from "../assets/images/find.png";
import onboard from "../assets/images/onboard.png";
import woman from "../assets/images/woman.png";
import quote from "../assets/images/quote.png";
import Background2 from "../assets/images/background2.jpg";
import Mockup from "../assets/images/mockup.png";
import adeyemo from "../assets/images/adeyemo.jpg";
import Hero from "../components/Home/Hero";
import Layout from "../components/LayoutSite";

// function Counter({ target, label }) {
//   const [count, setCount] = useState(0);

//   useEffect(() => {
//     const increment = target / 100;// Adjust the speed by changing the denominator
//     let currentCount = 0;

//     const counterInterval = setInterval(() => {
//       currentCount += increment;
//       if (currentCount >= target) {
//         currentCount = target;
//         clearInterval(counterInterval);
//       }
//       setCount(Math.ceil(currentCount));
//     }, 20);

//     // Clean up the interval when the component unmounts
//     return () => {
//       clearInterval(counterInterval);
//     };
//   }, [target]);

//   return (
//     <div className="flex flex-col gap-6">
//       <div className="text-5xl">{count}+</div>
//     </div>
//   );
// }

export default function Home() {
  return (
    <Layout>
      <div>
        {/* Hero section */}
        <Hero />

        {/* trusted */}
        {/* <div className="flex md:flex-row flex-col md:justify-evenly text-center mx-auto gap-4 py-20 items-center w-full bg-primary text-white">
        <div className="flex flex-col gap-6 mb-5">
          <div className="text-5xl"><Counter target={800000} label="Website visits per month" /></div>
          <p>Website visits per month</p>
        </div>
        <div className="flex flex-col gap-6">
          <div className="text-5xl"><Counter target={300000} label="Active users" /></div>
          <p>Active users</p>
        </div>
        <div className="flex flex-col gap-6">
          <div className="text-5xl"> <Counter target={50000} label="Delivered solutions" /></div>
          <p>Delivered solutions</p>
        </div>
        <div className="flex flex-col gap-6">
          <div className="text-5xl"><Counter target={100} label="Our members" /></div>
          <p>Our members</p>
        </div>
      </div> */}

        {/* Why us */}
        <div className="flex flex-col py-20 gap-4">
          <h1 className="md:text-[40px] text-[25px] text-center">
            Why choose mhapy?
          </h1>
          <p className="text-center text-[18px] w-2/3 mx-auto text-[#595959]">
            Personalized therapy recommendations tailored to your needs{" "}
          </p>
          <div className="grid md:grid-cols-3 grid-cols-1 px-20 py-8 gap-10 ">
            <Card
              className="px-8 py-20 flex flex-col bg-[#F4F4F4] justify-start shadow-none rounded-md"
              data-aos="slide-up"
            >
              <img src={ProductIcon} alt="ProductIcon" className="w-10 h-10" />
              <h1 className="text-base py-6 font-bold text-primary">
                Advanced matching algorithm
              </h1>
              <p className="text-base font-normal">
                Our AI-powered algorithm considers therapy type, specialization,
                location, availability, and your preferences to provide highly
                accurate therapist recommendations.
              </p>
            </Card>
            <Card
              className="px-8 py-20 flex flex-col  bg-[#F4F4F4] justify-start shadow-none rounded-md"
              data-aos="slide-in"
            >
              <img
                src={AchievememtIcon}
                alt="AchievememtIcon"
                className="w-10 h-10"
              />
              <h1 className="text-base py-6 font-bold text-primary">
                Customized therapy goals
              </h1>
              <p className="text-base font-normal">
                Input your specific therapy goals, preferences, and requirements
                to receive personalized therapist recommendations that align
                with your needs
              </p>
            </Card>

            <Card
              className="px-8 py-20 flex flex-col justify-start bg-[#F4F4F4] shadow-none rounded-md"
              data-aos="slide-left"
            >
              <img src={BadgeIcon} alt="BadgeIcon" className="w-10 h-12" />
              <h1 className="text-base py-6 font-bold text-primary">
                Trusted user reviews
              </h1>
              <p className="text-base font-normal">
                Make informed decisions based on user reviews and ratings for
                therapists. Choose therapists with a proven track record of
                providing quality therapy services.
              </p>
            </Card>
          </div>
        </div>
        <hr className="h-px my-5 border-0 bg-gray-700 w-3/5 mx-auto" />
        {/* find in 3 */}

        <div className="flex flex-col justify-center my-20">
          <h1 className="md:text-[40px] text-[20px] w-2/3 mx-auto text-center py-10 font-semibold">
            Find your perfect therapist in 3 simple steps
          </h1>
          <div className="flex flex-col px-4 gap-4 text-white">
            <div className="flex bg-primaryDark rounded-xl overflow-hidden p-6 relative h-auto">
              {/* <!-- Step 1 Card --> */}
              <div className="font-[700] md:text-[80px] text-[40px] absolute top-0 left-0 ml-6 mt-6">
                01
              </div>
              <div className="flex justify-center items-center gap-2 py-15 w-full">
                <div className="w-full flex flex-col md:flex-row items-center gap-2">
                  <div className="w-full flex justify-center">
                    {" "}
                    {/* Centering the image */}
                    <img
                      src={Find}
                      alt="Find your perfect"
                      className="mb-[42px] max-w-full h-auto max-h-[400px] object-contain"
                    />
                  </div>
                  <div className="w-full align-top text-center md:text-left">
                    <p className="text-[30px]">Tell us about yourself</p>
                    <p className="text-[16px]">
                      Answer a few questions about your legal needs,
                      preferences,
                      <br></br>and any specific requirements you may have.
                    </p>
                    <button className="w-fit h-fit content-end rounded font-bold text-white mt-20 flex gap-2 ">
                      Learn more{" "}
                      <img src={ArrowRight} alt="" className="text-white" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Step 2 and Step 3 Cards --> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* <!-- Step 2 Card --> */}
              <div className="flex flex-col md:flex-row gap-4 bg-[#C9CEFF] text-black rounded-xl px-8 py-6 relative w-full">
                <div className="font-[700] md:text-[80px] text-[40px] absolute top-0 left-0 ml-6 mt-6">
                  02
                </div>
                <div className="flex gap-4 items-center justify-center md:justify-start md:flex-col">
                  <div>
                    <img src={onboard} alt="Find your perfect" />
                  </div>
                  <div className="font-[700] flex flex-col w-full relative content-end gap-4 md:w-3/4 text-center">
                    <p className="md:text-[30px] text-[15px]">
                      Discover personalized recommendations
                    </p>
                    <p className="md:text-[16px] text-[12px] text-[#595959] leading-[20px] md:leading-[32px]">
                      Our AI algorithm will analyze your information and provide
                      you with a list of therapists who best match your needs.
                    </p>
                    <div className="py-2"></div> {/* Adding space */}
                    <button className="w-fit h-fit content-end rounded font-bold text-black -mt-2 flex gap-2 mx-auto">
                      Learn more{" "}
                      <img src={ArrowBlack} alt="" className="text-black" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-4 bg-[#DBD9E0] text-black rounded-xl px-8 py-6 relative w-full">
                <div className="font-[700] md:text-[80px] text-[40px] absolute top-0 left-0 ml-6 mt-6">
                  03
                </div>
                <div className="flex gap-4 items-center justify-center md:justify-start md:flex-col">
                  <div>
                    <img src={woman} alt="Find your perfect" />
                  </div>
                  <div className="font-[700] flex flex-col w-full relative content-end gap-4 md:w-3/4 text-center">
                    <p className="md:text-[30px] text-[15px]">
                      Choose your therapist and start your journey
                    </p>
                    <p className="md:text-[16px] text-[12px] text-[#595959] leading-[20px] md:leading-[32px]">
                      Review therapist profiles, read user reviews, and select
                      the therapist that resonates with confidence.
                    </p>
                    <div className="py-2"></div> {/* Adding space */}
                    <button className="w-fit h-fit content-end rounded font-bold text-black -mt-2 flex gap-2 mx-auto">
                      Learn more{" "}
                      <img src={ArrowBlack} alt="" className="text-black" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* testimonial */}
        <div className="flex flex-col py-10 gap-4 ">
          <h1 className="text-[24px] md:text-[30px] w-2/3 mx-auto text-center py-5 font-semibold">
            What Our Clients Say About Us
          </h1>
          <div className="w-[90%] mx-auto">
            <Carousel
              prevArrow={({ handlePrev }) => (
                <IconButton
                  variant="text"
                  color="white"
                  size="lg"
                  onClick={handlePrev}
                  className="!absolute text-gray-400 top-2/4 left-4 -translate-y-2/4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                    />
                  </svg>
                </IconButton>
              )}
              nextArrow={({ handleNext }) => (
                <IconButton
                  variant="text"
                  color="white"
                  size="lg"
                  onClick={handleNext}
                  className="!absolute text-gray-400 top-2/4 !right-4 -translate-y-2/4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    />
                  </svg>
                </IconButton>
              )}
              navigation={({ setActiveIndex, activeIndex, length }) => (
                <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                  {new Array(length).fill("").map((_, i) => (
                    <span
                      key={i}
                      className={`block h-1 cursor-pointer rounded-2xl  transition-all content-['wwee'] ${
                        activeIndex === i
                          ? "w-3 h-3 rounded-full bg-primary"
                          : "w-3 h-3 rounded-full border border-primary"
                      }`}
                      onClick={() => setActiveIndex(i)}
                    />
                  ))}
                </div>
              )}
              className="rounded-xl md:h-[600px] mx-auto"
            >
              <div>
                <Card className="max-w-[400px] md:max-w-[1060px] rounded-lg shadow-lg border-2 border-gray-500 mx-auto overflow-hidden">
                  <CardBody>
                    <div className="flex flex-col items-center md:flex-row">
                      <div className="md:w-1/3 flex-shrink-0 mb-4 md:mb-0 md:mr-8">
                        <div className="rounded-full overflow-hidden border-2 border-black w-full h-48 md:h-96">
                          <img
                            src={adeyemo}
                            className="w-full h-full object-cover rounded-full"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="md:w-2/3">
                        <div className="relative">
                          <img
                            src={quote}
                            className="absolute top-0 left-0 w-8 md:w-12"
                            alt="quote"
                          />
                          <img
                            src={quote}
                            className="absolute bottom-0 right-0 w-8 md:w-12 transform rotate-180"
                            alt="quote"
                          />
                          <Typography
                            variant="lead"
                            color="gray"
                            className="font-light py-5 md:py-8 text-center md:text-left"
                          >
                            mhapy made finding a therapist super easy. It just
                            had to fill out what I wanted in my ideal therapist.
                            I liked how it ranked the therapists so I could pick
                            the best one for me. It's been a big help for my
                            mental health journey.
                          </Typography>
                          <Typography className="font-bold text-center md:text-left">
                            Idowu Adeyemo
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              {/* Add more carousel items as needed */}
            </Carousel>
          </div>
        </div>

        {/* Join waitlist */}

        <div className="hero md:px-8 px-4 flex md:flex-row flex-col items-center w-full">
          <div className="flex bg-primary w-full md:px-20 px-4 gap-4 rounded-xl md:gap-6 items-center text-white">
            <div className="left w-full md:flex md:flex-col md:m-3 gap-6">
              <p className="text-[10px] md:text-lg">
                Ready to find your perfect therapist?
              </p>
              <p className=" font-semibold md:text-2xl text-[11px] md:leading-[52px] leading-[16px] mb-3">
                Join our <br /> waitlist now!
              </p>
              <p className="text-[10px] md:text-[24px] w-full md:w-2/3 mb-3">
                Be the first to experience mhapy and receive exclusive offers
                when we launch.
              </p>

              <div className="relative flex w-full max-w-[24rem]">
                {/* Adjust Input and Button styling as needed */}
                <Input
                  type="email"
                  label="Email Address"
                  className="pr-12 bg-white"
                  containerProps={{
                    className: "min-w-0",
                  }}
                />
                <Button
                  size="sm"
                  className="!absolute right-1 top-1 rounded bg-primary"
                >
                  Join
                </Button>
              </div>
            </div>
            <div className="right relative w-full md:py-10 py-5 rounded-xl justify-center">
              <img
                src={Background2}
                alt="hero"
                className="rounded-xl w-full h-auto"
              />
            </div>
          </div>
        </div>

        <div className="hero md:px-8 px-4 flex md:flex-row -mb-10 mt-20 md:mt-40 flex-col items-center w-full">
          <div className="flex bg-[#44189026] w-full md:px-20 px-4 rounded-tr-3xl rounded-tl-3xl gap-6 items-center text-black">
            <div className="left w-full md:flex md:flex-col md:m-3 gap-6">
              <p className="font-semibold md:text-3xl text-[15px] md:leading-[52px] leading-[16px] mt-5">
                Download mhapy app now!
              </p>
              <p className="text-[11px] md:text-lg md:leading-[24px] leading-[15px] text-[#595959]">
                Download the app today and experience a seamless way to connect
                with peers and meet new people with ease. Observe patterns and
                trends in your mental health, talk to Ruby our AI-powered
                chatbot, and more all at your fingertips.
              </p>

              <div className="relative flex w-full max-w-[24rem]">
                <Input
                  type="email"
                  label="Email Address"
                  className="pr-12 bg-white"
                  containerProps={{
                    className: "min-w-0",
                  }}
                />
                <Button
                  size="sm"
                  className="!absolute right-1 top-1 rounded bg-primary"
                >
                  Join
                </Button>
              </div>

              {/* Mockup image (on mobile) */}
              <div className="relative w-full md:hidden py-5 justify-center">
                <img
                  src={Mockup}
                  alt="hero"
                  className="rounded-xl w-full h-auto"
                />
              </div>

              {/* Store buttons */}
              <div className="flex md:flex-row flex-col justify-start pt-4 gap-2 w-full md:mb-5 mb-10">
                <button
                  href=""
                  className="flex px-4 md:px-8 gap-2 border justify-center items-center h-fit rounded-lg shadow-inner py-2 md:text-sm text-xs bg-black text-white"
                >
                  <img
                    data-aos="slide-left"
                    height={10}
                    width={10}
                    src="https://i.imgur.io/6Qf5V8c_d.webp"
                    className="w-4 h-auto"
                    alt="playstore"
                  />
                  <div className="leading-3">
                    <p>Get it on</p>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.mobile.mhapy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <h4 className="font-bold text-sm md:text-xl"> */}
                        Google Play
                      {/* </h4> */}
                    </a>
                  </div>
                </button>

                <button
                  href=""
                  className="mb-3 flex px-4 md:px-8 gap-2 border justify-center border-black items-center bg-white text-black h-fit rounded-lg shadow-inner py-2 md:text-sm text-xs"
                >
                  <img
                    src="https://i.imgur.io/H4bHBxx_d.webp"
                    height={10}
                    width={10}
                    className="w-4 h-auto"
                    alt="apple store"
                  />
                  <div data-aos="fade-up" className="leading-3">
                    <p>Get it on</p>
                    <a
                      href="https://apps.apple.com/kw/app/mhapy/id6450757194"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <h4 className="font-bold text-sm md:text-xl"> */}
                        Apple store
                      {/* </h4> */}
                    </a>
                  </div>
                </button>
              </div>
            </div>

            {/* Mockup image (on desktop) */}
            <div className="right relative w-full md:py-10 py-5 justify-center hidden md:block">
              <img
                src={Mockup}
                alt="hero"
                className="rounded-xl w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
