import React, { useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";

export default function BookingInfo({
  therapistEmail,
  therapistLink,
  therapistName,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    therapistLink: therapistLink,
    therapistEmail: therapistEmail,
    therapistName: therapistName,
  });
  const [validationErrors, setValidationErrors] = useState({});

  const handleOpen = () => setOpen(!open);
  const navigateToExternalLink = () => {
    const linkToNavigate = therapistLink || window.location.href;

    window.open(linkToNavigate, "_blank");
  };

  const handleChange = (e) => {
    // Update form data on input change
    setFormData({ ...formData, [e.target.id]: e.target.value });

    // Clear validation error when user starts typing
    setValidationErrors({ ...validationErrors, [e.target.id]: "" });
  };

  const isValidEmail = (email) => {
    // Email validation logic
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    setLoading(true); // Set loading state to true to disable the submit button
    // Basic validation
    // console.log(therapistLink);
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (Object.keys(errors).length === 0) {
      try {
        // const response = await fetch(
        //   "http://localhost:3300/api/v1/therapists/booking",
        //   {
        const response = await fetch(
          "https://mhapy.ca/api/v1/therapists/booking",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ formData }),
          }
        );
        if (response.ok) {
          toast.success("Submit Successful");
          navigateToExternalLink();
          handleOpen();
        } else {
          setValidationErrors(errors);
          toast.error("Submit failed");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Submit failed");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false); // Reset loading state if there are validation errors
    }
  };
  return (
    <>
      <Button onClick={handleOpen}>Book an Appointment</Button>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <Typography variant="h4" color="blue-gray">
              Client Form{" "}
            </Typography>
            <Typography
              className="mb-3 font-normal"
              variant="paragraph"
              color="gray"
            >
              Please enter your full name and email to connect you with our
              therapist.
            </Typography>
            <Typography className="-mb-2" variant="h6">
              Your Name
            </Typography>
            <Input
              id="name"
              label="Name"
              size="lg"
              value={formData.name}
              onChange={handleChange}
            />
            {validationErrors.name && (
              <Typography variant="caption" color="red">
                {validationErrors.name}
              </Typography>
            )}
            <Typography className="-mb-2 mt-2" variant="h6">
              Your Email
            </Typography>
            <Input
              id="email"
              label="Email"
              size="lg"
              value={formData.email}
              onChange={handleChange}
            />
            {validationErrors.email && (
              <Typography variant="caption" color="red">
                {validationErrors.email}
              </Typography>
            )}
          </CardBody>
          <CardFooter className="pt-0">
            {/* <button
              className="w-full bg-primary text-white p-3 rounded-md"
              onClick={handleSubmit}
              disabled={loading} // Disable the button when loading is true
            >
              {loading ? "Submitting..." : "Submit"}
            </button> */}

            <button
              className="w-full bg-primary text-white p-3 rounded-md"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <Hourglass
                    type="TailSpin"
                    color="#fff"
                    height={24}
                    width={24}
                  />
                  <span className="ml-2 font-semibold">Submitting...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </CardFooter>
        </Card>
      </Dialog>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
