import React from 'react';

function Question1({ question, selectedOption, handleRadioChange }) {
  return (
    <div>
      <div className='font-bold'>
        <span>
          {question.question.split(' ').map((word, index, array) => (
            index === array.length - 1 ? 
            <span style={{fontSize: '24px', fontStyle: 'italic'}}>{word}</span> : 
            <span>{word} </span>
          ))}
        </span>
      </div>
      <br/>
      {question.options.map((option, index) => (
        <label className="radio-container" key={index}>
          <input
            type="radio"
            name={question.name}
            value={option}
            onChange={() => handleRadioChange(option)}
            checked={selectedOption === option}
            required
          />
          <span className="radio-label">{option}</span>
        </label>
      ))}
    </div>
  );
}

export default Question1;
