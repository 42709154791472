import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  // DialogFooter,
  // Button,
} from "@material-tailwind/react";

// Function to split the bio text into paragraphs
function splitBioIntoParagraphs(bioText) {
  const words = bioText.split(" ");
  let currentParagraph = "";
  const paragraphs = [];

  for (let i = 0; i < words.length; i++) {
    currentParagraph += words[i] + " ";
    if (currentParagraph.split(" ").length >= 100 && words[i].endsWith(".")) {
      paragraphs.push(currentParagraph);
      currentParagraph = "";
    }
  }

  // Add the remaining text as a last paragraph if it exists
  if (currentParagraph !== "") {
    paragraphs.push(currentParagraph);
  }

  return paragraphs;
}

export default function BioCard({ therapistBio, therapistImage, bookingLink }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  // Splitting the bio into paragraphs
  const bioParagraphs = splitBioIntoParagraphs(therapistBio);

  return (
    <>
      <p className="cursor-pointer font-bold italic" onClick={handleOpen}>See More</p>
      <Dialog
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size={"lg"}
      >
        <DialogHeader style={{ backgroundColor: '#441890', color: 'white', borderRadius: '0.5rem 0.5rem 0 0' }}>Therapist Full Bio</DialogHeader>
        <DialogBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <img src={therapistImage} alt="Therapist" className="w-1/3 mr-4" style={{ height: "300px", width: "300px", objectFit: "cover", float: "left", marginRight: "20px" }} />
          <div style={{ height: "100%" }}>
            {bioParagraphs.map((paragraph, index) => (
              <div key={index}>
                <p>{paragraph}</p>
                {index !== bioParagraphs.length - 1 && <div style={{ marginBottom: '1em' }} />}
              </div>
            ))}
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}