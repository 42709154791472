// Layout.js
import React from 'react';
import Nav from './Nav';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className='overflow-hidden'>
      <Nav />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
